$(function() {

  // Get the csrf-token from meta tag
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  // Smooth Scrolling to an ID on the current page.
  $('a.page-scroll').bind('click', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: $($anchor.attr('href')).offset().top
    }, 1500, 'easeInOutExpo');
    e.preventDefault();
  });

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').click(function() {
    $('.navbar-toggle:visible').click();
  });

  // Owl Carousel Settings
  $(".testimonials-carousel").owlCarousel({
    singleItem: true,
    navigation: true,
    pagination: true,
    autoHeight: true,
    autoPlay: true,
    stopOnHover: true,
    navigationText: [
      "<i class='fa fa-angle-left fa-lg'></i>",
      "<i class='fa fa-angle-right fa-lg'></i>"
    ],
    transitionStyle: "backSlide"
  });

  // Text Rotator
  $(".rotate").textrotator({
    animation: "dissolve",
    separator: ",",
    speed: 3000
  });

  // Google Maps API


  var mapLocation = new google.maps.LatLng(43.726436, 7.337590);

  var map = new GMaps({

    el: '#gmaps',
    center: mapLocation,
    scrollwheel: false,
    draggable: false,
    disableDefaultUI: false
  });


  var styles = [{
    "stylers": [{
      "hue": "#00aaff"
    }, {
      "saturation": -100
    }, {
      "gamma": 2.15
    }, {
      "lightness": 12
    }]
  }];

  map.addStyle({
    styles: styles,
    mapTypeId: "map_style"
  });

  map.setStyle("map_style");
  map.addMarker({

    position: mapLocation,
    icon: "img/map-marker.png"
  });

  // Waypoint + CountTo
  $('#stats').waypoint(function() {

    $('.timer').delay(6000).countTo();
  }, {
    offset: '70%',
    triggerOnce: true
  });

  // Wow Animation on scroll
  var wow = new WOW({
    mobile: false
  });
  wow.init();

  // Contact Form
  $('#contactForm').on('submit', function(e) {
    e.preventDefault();
    var form = $(this);

    $.ajax({
      type: 'POST',
      url: form.prop('action'),
      data: form.serialize(),
      success: function() {
        $('#contact-errors .alert').addClass('alert-success')
          .removeClass('alert-danger')
          .html('Merci de nous avoir contacté ! A très bientôt !')
          .fadeIn(500).delay(7500).slideUp(500);
        form[0].reset();
      },
      error: function(data) {

          var errors = data.responseJSON;
          errorsHtml = '<ul class="list-unstyled">';

          $.each(errors, function(key, value) {
            errorsHtml += '<li>' + value + '</li>';
          });

          errorsHtml += '</div>';
          $('#contact-errors .alert').addClass('alert-danger')
            .removeClass('alert-success')
            .html(errorsHtml)
            .fadeIn(500);
        } // End error function
    }); // End $.ajax
  }); // End $('#contactForm')

  // Mailchimp Form
  $('#mailchimp').on('submit', function(e) {
    e.preventDefault(); // Prevent the form from submitting

    var form = $(this);
    var email = form.find('input[name="sub-email"]');

    $.ajax({
      type: 'POST',
      url: form.prop('action'),
      data: form.serialize(),
      dataType: "json",
      success: function(data) {

        $('#mailchimp-errors .alert').addClass('alert-success')
          .removeClass('alert-danger')
          .html(data.message)
          .fadeIn(500).delay(7500).slideUp(500);
        form[0].reset();
      },
      error: function(data) {

          var errors = data.responseJSON; // Get the errors response data.
          errorsHtml = '<ul class="list-unstyled">';

          $.each(errors, function(key, value) {
            errorsHtml += '<li>' + value + '</li>';
          });

          errorsHtml += '</ul>';
          $('#mailchimp-errors .alert').addClass('alert-danger')
            .removeClass('alert-success')
            .html(errorsHtml).fadeIn(500);
        } // End error function
    }); // End $.ajax
  }); // End $('#mailchimp')



});

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-71252547-1', 'auto');
ga('send', 'pageview');
